import React, { useState, useEffect, Suspense } from 'react';
import { Switch, Route, Redirect, useHistory } from 'react-router-dom';
import jwt from 'jsonwebtoken';

import './App.scss';
import { Sidebar, Header, Auth, Loader } from './components';
import PageRoutes from './Routes';
import { getUser } from './API/Users';
import { PublicRoute, PrivateRoute } from './RouteType';
import { IContextUserDetail } from './interfaces';
import { Documentation, releaseNotes, FeedbackExportPage } from './pages';
import { checkAccessForPage, convertToEpoch } from './utils';
import { useGAPageView, useSetEmployeeId } from './hooks/useGoogleAnalytics';
import ActiveSessions from './pages/activeSessions/ActiveSessions';
import { onLogout } from './API/Admin';
import ExpiryModal from './pages/activeSessions/ExpiryModel';
import { extendSession } from './API/Session';

const initialUserState: IContextUserDetail = {
    employeeId: 0,
    email: '',
    role: 'EMPLOYEE',
    employeeName: '',
    managerName: '',
    isManager: false,
    isExecutive: false,
    hasSubordinates: false,
};

export const EmployeeContext = React.createContext<IContextUserDetail>(initialUserState);

const GoalsRoutes: React.FC = () => {
    const [toggle, setToggle] = useState(false);
    const [user, setUser] = useState<IContextUserDetail>(initialUserState);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [showExpiryModel, handleExpiryModel] = useState(false);
    const [warningTimeoutId, setWarningTimeoutId] = useState<NodeJS.Timeout | null>(null);
    const [logoutTimeoutId, setLogoutTimeoutId] = useState<NodeJS.Timeout | null>(null);
    const history = useHistory();
    const { triggerPageView } = useGAPageView();

    const closeModal = () => {
        handleExpiryModel(false);
    };

    useEffect(() => {
        return history.listen((location) => {
            triggerPageView(location);
        });
    }, [history]);

    useEffect(() => {
        if (isAuthenticated) {
            useSetEmployeeId(user.employeeId);
            checkSessionExpiry();
        }
    }, [isAuthenticated]);
    const checkSessionExpiry = async () => {
        const sessionExpiryMilliseconds = localStorage.getItem('session_expiry');
        if (!sessionExpiryMilliseconds) return;
        const sessionExpiry = parseInt(sessionExpiryMilliseconds, 10) / 1000;

        const currentTime = Math.floor(Date.now() / 1000);
        if (sessionExpiry <= currentTime) {
            alert('Session expired. You will be logged out.');
            await onLogout();
            return;
        }
        const timeRemaining = sessionExpiry - currentTime;
        if (timeRemaining <= 2 * 60) {
            handleExpiryModel(true);
        }
        if (warningTimeoutId) clearTimeout(warningTimeoutId);
        if (logoutTimeoutId) clearTimeout(logoutTimeoutId);

        //15 mins before
        const newWarningTimeout = setTimeout(() => {
            handleExpiryModel(true);
        }, (timeRemaining - 2 * 60) * 1000);

        const newLogoutTimeout = setTimeout(async () => {
            alert('Session expired. You will be logged out.');
            await onLogout();
        }, (timeRemaining - 20) * 1000);
        setWarningTimeoutId(newWarningTimeout);
        setLogoutTimeoutId(newLogoutTimeout);
    };
    const handleSessionExtension = async () => {
        const res = await extendSession();
        if (res) {
            alert('Session extended successfully');
            checkSessionExpiry();
        } else {
            alert('Failed to extend session');
        }
        handleExpiryModel(false);
    };

    const handleAuthentication = async () => {
        const res = await fetch(`${process.env.REACT_APP_API_HOST}/auth/access-token`, {
            credentials: 'include',
        });
        if (!res.ok) {
            history.push('/signin');
        }
        const data = await res.json();
        localStorage.setItem('access_token', data.access_token);
        localStorage.setItem('token', data.id_token);
        localStorage.setItem('session_expiry', data.session_expiry);
        const tokenStr = data.id_token;
        if (tokenStr) {
            const token = jwt.decode(tokenStr) as { exp: number; unique_name: string };
            if (token) {
                if (typeof token.exp !== 'undefined' && token.exp < convertToEpoch(new Date()) / 1000) {
                    setIsAuthenticated(false);
                } else {
                    const userInfo = await getUser(token.unique_name);
                    if (userInfo) {
                        setUser({
                            ...userInfo,
                            employeeId: userInfo.employeeId,
                            employeeName: userInfo.employeeName,
                            role: userInfo.role,
                            email: userInfo.email,
                            managerName: userInfo.managerName,
                            isManager: userInfo.isManager,
                            isExecutive: userInfo.isExecutive,
                        });
                        setIsAuthenticated(true);
                    } else {
                        history.push(`/unauthorized`);
                    }
                }
            }
        }
        setIsLoading(false);
    };

    useEffect(() => {
        handleAuthentication();
    }, []);

    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (
                <div>
                    <EmployeeContext.Provider value={user}>
                        <PublicRoute
                            restricted={true}
                            component={Auth}
                            path={`/signin`}
                            exact
                            isAuthenticated={isAuthenticated}
                            handleAuthentication={handleAuthentication}
                        />
                        {isAuthenticated && (
                            <Sidebar toggleActive={toggle} toggleHandler={(value) => setToggle(value)} />
                        )}
                        <Suspense fallback={<Loader />}>
                            <div className="main">
                                {isAuthenticated && <Header toggleHandler={(value) => setToggle(value)} />}
                                <Switch>
                                    <Route exact path={`/`} render={() => <Redirect to="/dashboard" />}></Route>
                                    {PageRoutes.map((route, index) => {
                                        //giving access to all routes when ot authenticated for redirection to login page
                                        if (
                                            checkAccessForPage(
                                                user.role,
                                                route.role,
                                                user.isManager,
                                                user.isExecutive,
                                            ) ||
                                            !isAuthenticated
                                        ) {
                                            if (route.children.length > 0) {
                                                return (
                                                    <Route
                                                        key={index}
                                                        path={route.path}
                                                        render={() => {
                                                            return (
                                                                <div>
                                                                    <PrivateRoute
                                                                        key={index}
                                                                        path={route.path}
                                                                        component={route.component}
                                                                        isAuthenticated={isAuthenticated}
                                                                        userId={user.employeeId}
                                                                        exact
                                                                    />
                                                                    {route.children.map((childRoutes) => (
                                                                        <PrivateRoute
                                                                            key={`${childRoutes.path}-${index}`}
                                                                            path={`${childRoutes.path}`}
                                                                            component={childRoutes.component}
                                                                            isAuthenticated={isAuthenticated}
                                                                            userId={user.employeeId}
                                                                            exact
                                                                        />
                                                                    ))}
                                                                </div>
                                                            );
                                                        }}
                                                    />
                                                );
                                            } else
                                                return (
                                                    <PrivateRoute
                                                        key={index}
                                                        path={route.path}
                                                        component={route.component}
                                                        isAuthenticated={isAuthenticated}
                                                        userId={user.employeeId}
                                                    />
                                                );
                                        }
                                    })}

                                    <PrivateRoute
                                        path={`/active-sessions`}
                                        component={ActiveSessions}
                                        isAuthenticated={isAuthenticated}
                                        userId={user.employeeId}
                                    />
                                    <PrivateRoute
                                        path={`/documentation`}
                                        component={Documentation}
                                        isAuthenticated={isAuthenticated}
                                        userId={user.employeeId}
                                    />
                                    <PrivateRoute
                                        path={`/releases`}
                                        component={releaseNotes}
                                        isAuthenticated={isAuthenticated}
                                        userId={user.employeeId}
                                    />
                                    <PrivateRoute
                                        path={`/aprfeedback/export/:aprFeedbackId`}
                                        component={FeedbackExportPage}
                                        isAuthenticated={isAuthenticated}
                                        userId={user.employeeId}
                                    />
                                    {/* <Route component={NotFound} /> */}
                                </Switch>
                            </div>
                            {showExpiryModel && (
                                <ExpiryModal closeModal={closeModal} handleSessionExtension={handleSessionExtension} />
                            )}
                        </Suspense>
                    </EmployeeContext.Provider>
                </div>
            )}
        </>
    );
};

export default GoalsRoutes;
