import React from 'react';
import { Button, Modal } from 'react-bootstrap';
interface Props {
    closeModal: () => void;
    handleSessionExtension: () => Promise<void>;
}
const ExpiryModal: React.FC<Props> = ({ closeModal, handleSessionExtension }) => {
    return (
        <>
            <Modal show={true} onHide={closeModal} className="confirmation-modal">
                <Modal.Header closeButton>
                    <Modal.Title>Session Expiration Warning</Modal.Title>
                </Modal.Header>
                <Modal.Body>Your session is about to expire. Would you like to extend your session?</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleSessionExtension}>
                        Yes
                    </Button>
                    <Button variant="outline-secondary" onClick={closeModal}>
                        No
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ExpiryModal;
