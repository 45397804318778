import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { Table } from 'react-bootstrap';
import CONSTANTS from '../constants';

const ActiveSessionsSkeleton: React.FC = () => {
    return (
        <div className="active-sessions-skeleton">
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>User ID</th>
                        <th>IP Address</th>
                        <th>Browser</th>
                        <th>Login Time</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {/* Render several skeleton rows */}
                    {[...Array(3)].map((_, index) => (
                        <TableRowSkeleton key={index} />
                    ))}
                </tbody>
            </Table>
        </div>
    );
};

const TableRowSkeleton: React.FC = () => {
    return (
        <tr>
            {/* User ID */}
            <td>
                <SkeletonTheme
                    color={CONSTANTS.COLORS.SKELETON_COLOR}
                    highlightColor={CONSTANTS.COLORS.SKELETON_HIGHLIGHT_COLOR}
                >
                    <Skeleton width={50} />
                </SkeletonTheme>
            </td>

            {/* IP Address */}
            <td>
                <SkeletonTheme
                    color={CONSTANTS.COLORS.SKELETON_COLOR}
                    highlightColor={CONSTANTS.COLORS.SKELETON_HIGHLIGHT_COLOR}
                >
                    <Skeleton width={100} />
                </SkeletonTheme>
            </td>

            {/* Browser */}
            <td>
                <SkeletonTheme
                    color={CONSTANTS.COLORS.SKELETON_COLOR}
                    highlightColor={CONSTANTS.COLORS.SKELETON_HIGHLIGHT_COLOR}
                >
                    <Skeleton width={80} />
                </SkeletonTheme>
            </td>

            {/* Login Time */}
            <td>
                <SkeletonTheme
                    color={CONSTANTS.COLORS.SKELETON_COLOR}
                    highlightColor={CONSTANTS.COLORS.SKELETON_HIGHLIGHT_COLOR}
                >
                    <Skeleton width={150} />
                </SkeletonTheme>
            </td>

            {/* Actions */}
            <td>
                <SkeletonTheme
                    color={CONSTANTS.COLORS.SKELETON_COLOR}
                    highlightColor={CONSTANTS.COLORS.SKELETON_HIGHLIGHT_COLOR}
                >
                    <Skeleton width={120} />
                </SkeletonTheme>
            </td>
        </tr>
    );
};

export default ActiveSessionsSkeleton;
